import axios from "axios";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {addItemToLoadingQueue, setBestFittingOpening, setElevation} from '../unity/react-unity-adapter'

//region Elevation

export const getElevation = createAsyncThunk('elevation/get', async ({projectId, elevationId, forceUpdate}) => {
    try {
        console.log('Get elevation: ', projectId, elevationId, forceUpdate)
        return await axios.get(`/api/projects/${projectId}/elevationPlan/${elevationId}` + (forceUpdate ? `/forceUpdate` : ``))
    } catch (err) {
        return err.message
    }
})

export const createElevation = createAsyncThunk('elevation/create', async ({projectId, elevation, plan}) => {
    try {
        console.log('Create elevation: ', projectId, elevation, plan)
        addItemToLoadingQueue("UploadPlan");
        if (plan) {
            const data = new FormData()
            elevation.planName = plan.name;
            data.append('elevationDTO', new Blob([JSON.stringify(elevation)], {
                type: "application/json"
            }));
            data.append('file', new Blob([plan], {
                type: "application/pdf"
            }));
            return await axios.post(`/api/projects/${projectId}/elevationPlan/newPlan`, data)
        } else {
            return await axios.post(`/api/projects/${projectId}/elevationPlan/existingPlan`, elevation)
        }

    } catch (err) {
        return err.message
    }
})

export const renameElevation = createAsyncThunk('elevation/rename', async ({projectId, elevationId, name}) => {
    try {
        console.log('Rename elevation: ', projectId, elevationId, name)
        return await axios.put(`/api/projects/${projectId}/elevationPlan/${elevationId}/rename`, name, {
            headers: {
                'Content-Type': 'text/plain'
            }
        })
    } catch (err) {
        return err.message
    }
})

export const duplicateElevation = createAsyncThunk('elevation/duplicate', async ({projectId, elevationId, crop}) => {
    try {
        console.log('Duplicate elevation: ', projectId, elevationId, crop)
        return await axios.post(`/api/projects/${projectId}/elevationPlan/${elevationId}/duplicate`, crop)
    } catch (err) {
        return err.message
    }
})

export const deleteElevation = createAsyncThunk('elevation/delete', async ({projectId, elevationId}) => {
    try {
        console.log('Delete elevation: ', projectId, elevationId)
        return await axios.delete(`/api/projects/${projectId}/elevationPlan/${elevationId}`)
    } catch (err) {
        return err.message
    }
})

//endregion

export const setCrop = createAsyncThunk('elevation/crop', async ({projectId, elevationId, crop}) => {
    try {
        console.log('Set crop: ', projectId, crop)
        return await axios.post(`/api/projects/${projectId}/elevationPlan/${elevationId}/crop`, crop)
    } catch (err) {
        return err.message
    }
})

export const setProjectionLocation = createAsyncThunk('elevation/projectionLocation', async ({
                                                                                                 projectId,
                                                                                                 elevationId,
                                                                                                 location
                                                                                             }) => {
    try {
        console.log('Set Projection Location: ', projectId, location)
        return await axios.post(`/api/projects/${projectId}/elevationPlan/${elevationId}/setProjectionLocation`, location)
    } catch (err) {
        return err.message
    }
})

export const deleteProjectionLocation = createAsyncThunk('elevation/projectionLocation', async ({
                                                                                                    projectId,
                                                                                                    elevationId
                                                                                                }) => {
    try {
        console.log('Delete Projection Location: ', projectId)
        return await axios.delete(`/api/projects/${projectId}/elevationPlan/${elevationId}/projectionLocation`)
    } catch (err) {
        return err.message
    }
})


//region Panelization Areas

export const updatePanelizationAreas = createAsyncThunk('panelizationArea/update', async ({
                                                                                              projectId,
                                                                                              elevationId,
                                                                                              panelizationAreas
                                                                                          }) => {
    try {
        console.log('Update panelization area: ', panelizationAreas)
        return await axios.put(`/api/projects/${projectId}/elevation/${elevationId}/panelizationArea`, panelizationAreas)
    } catch (err) {
        return err.message
    }
})

//endregion

//region Opening Group

export const createOpeningGroup = createAsyncThunk('openingGroup/create', async ({
                                                                                     projectId,
                                                                                     elevationId,
                                                                                     openingGroup
                                                                                 }) => {
    try {
        console.log('Create opening group: ', projectId, openingGroup)
        return await axios.post(`/api/projects/${projectId}/elevation/${elevationId}/openingGroup`, openingGroup)
    } catch (err) {
        return err.message
    }
})

export const updateOpeningGroups = createAsyncThunk('openingGroup/update', async ({
                                                                                      projectId,
                                                                                      elevationId,
                                                                                      openingGroups
                                                                                  }) => {
    try {
        console.log('Update opening groups: ', projectId, elevationId, openingGroups)
        return await axios.put(`/api/projects/${projectId}/elevation/${elevationId}/openingGroup`, openingGroups)
    } catch (err) {
        return err.message
    }
})

export const updateOpeningGroupName = createAsyncThunk('openingGroup/updateName', async ({
                                                                                             projectId,
                                                                                             elevationId,
                                                                                             openingGroup
                                                                                         }) => {
    try {
        console.log('Update opening group name: ', openingGroup)
        return await axios.put(`/api/projects/${projectId}/elevation/${elevationId}/openingGroup/name`, openingGroup)
    } catch (err) {
        return err.message
    }
})

export const deleteOpeningGroup = createAsyncThunk('openingGroup/delete', async ({
                                                                                     projectId,
                                                                                     elevationId,
                                                                                     openingGroupId
                                                                                 }) => {
    try {
        console.log('Delete opening group: ', openingGroupId)
        return await axios.delete(`/api/projects/${projectId}/elevation/${elevationId}/openingGroup/${openingGroupId}`)
    } catch (err) {
        return err.message
    }
})

export const findBestFittingOpening = createAsyncThunk('detection/getWindowTemplate', async ({
                                                                                                 projectId,
                                                                                                 elevationId,
                                                                                                 initialPosition
                                                                                             }) => {
    try {
        console.log('Find best fitting opening: ', initialPosition)
        return await axios.post(`/api/projects/${projectId}/elevation/${elevationId}/detection/getWindowTemplate`, initialPosition)
    } catch (err) {
        return err.message
    }
})

export const recognizeOpeningGroup = createAsyncThunk('detection/addNewWindows', async ({
                                                                                            projectId,
                                                                                            elevationId,
                                                                                            finalPosition
                                                                                        }) => {
    try {
        console.log('Recognize opening group: ', finalPosition)
        return await axios.post(`/api/projects/${projectId}/elevation/${elevationId}/detection/addNewWindows`, finalPosition)
    } catch (err) {
        return err.message
    }
})

export const updateOpeningGroupMatchRate = createAsyncThunk('detection/matchRate', async ({
                                                                                              projectId,
                                                                                              elevationId,
                                                                                              openingGroup
                                                                                          }) => {
    try {
        console.log('Update opening group match rate: ', openingGroup)
        return await axios.put(`/api/projects/${projectId}/elevation/${elevationId}/detection/matchRate`, openingGroup)
    } catch (err) {
        return err.message
    }
})

//endregion

//region Moulding

export const updateMouldings = createAsyncThunk('moulding/update', async ({projectId, elevationId, mouldings}) => {
    try {
        console.log('Update mouldings: ', mouldings)
        return await axios.post(`/api/projects/${projectId}/elevation/${elevationId}/moulding`, mouldings)
    } catch (err) {
        return err.message
    }
})

//endregion

//region Material Face

export const updateMaterials = createAsyncThunk('materialFaces/update', async ({
                                                                                   projectId,
                                                                                   elevationId,
                                                                                   materialLines,
                                                                                   materialFaces
                                                                               }) => {
        const body = {
            "materialLines": materialLines,
            "materialFaces": materialFaces
        }

        try {
            console.log('Update material faces', body)
            return await axios.put(`/api/projects/${projectId}/elevationPlan/${elevationId}/materialFaces`, body)
        } catch (err) {
            return err.message
        }
    }
)


//endregion

//region Architectural Lines

export const updateArchitecturalLines = createAsyncThunk('architecturalLine/update', async ({
                                                                                                projectId,
                                                                                                elevationId,
                                                                                                architecturalLines
                                                                                            }) => {
    try {
        console.log('Update architectural line: ', architecturalLines)
        return await axios.put(`/api/projects/${projectId}/elevation/${elevationId}/architecturalLine`, architecturalLines)
    } catch (err) {
        return err.message
    }
})

//endregion0

//region Areas To Paneling

export const invertAreasToPaneling = createAsyncThunk('areaToPaneling/invert', async ({projectId, elevationId}) => {
    try {
        console.log('Invert areas to paneling')
        return await axios.put(`/api/projects/${projectId}/elevation/${elevationId}/areaToPaneling/invert`)
    } catch (err) {
        return err.message
    }
})

export const resetAreaToPaneling = createAsyncThunk('areaToPaneling/reset', async ({projectId, elevationId}) => {
    try {
        console.log('Reset areas to paneling')
        return await axios.put(`/api/projects/${projectId}/elevation/${elevationId}/areaToPaneling/reset`)
    } catch (err) {
        return err.message
    }
})

export const getSimilarOpenings = createAsyncThunk('detection/similarOpenings', async ({
                                                                                           projectId,
                                                                                           elevationId
                                                                                       }) => {
    try {
        console.log('Find similar Openings ')
        return await axios.get(`/api/projects/${projectId}/elevation/${elevationId}/detection/similarOpenings`)
    } catch (err) {
        return err.message
    }
})

//endregion

const elevationSlice = createSlice({
    name: 'elevation',
    initialState: {
        elevation: {
            id: '',
            name: '',
            plan: null,
            crop: null,
            croppedPlan: null,
            mouldings: [],
            materialLines: [],
            architecturalLines: [],
            areasToPaneling: [],
        },
        loading: false,
    },
    reducers: {},
    extraReducers(builder) {
        builder

            //region Elevation

            .addCase(getElevation.pending, (state) => {
                state.loading = true
            })
            .addCase(getElevation.fulfilled, (state, {payload}) => {
                const elevation = payload.data
                if (!elevation) {
                    return
                }
                state.elevation = elevation
                state.loading = false
                setElevation(elevation)
            })
            .addCase(getElevation.rejected, (state) => {
                state.loading = false
            })
            .addCase(createElevation.pending, (state) => {
                state.loading = true
            })
            .addCase(createElevation.fulfilled, (state, {payload}) => {
                const elevation = payload.data
                if (!elevation) {
                    return
                }
                state.elevation = elevation
                state.loading = false
                setTimeout(() => setElevation(payload.data), 1000)
            })
            .addCase(createElevation.rejected, (state) => {
                state.loading = false
            })
            .addCase(renameElevation.pending, (state) => {
                state.loading = true
            })
            .addCase(renameElevation.fulfilled, (state, {payload}) => {
                const elevation = payload.data
                if (!elevation) {
                    return
                }
                state.elevation = elevation
                state.loading = false
                setElevation(elevation)
            })
            .addCase(renameElevation.rejected, (state) => {
                state.loading = false
            })
            .addCase(duplicateElevation.pending, (state) => {
                state.loading = true
            })
            .addCase(duplicateElevation.fulfilled, (state, {payload}) => {
                const elevation = payload.data
                if (!elevation) {
                    return
                }
                state.elevation = elevation
                state.loading = false
                setElevation(elevation)
            })
            .addCase(duplicateElevation.rejected, (state) => {
                state.loading = false
            })
            //endregion

            //region Crop

            .addCase(setCrop.pending, (state) => {
                state.loading = true
            })
            .addCase(setCrop.fulfilled, (state, {payload}) => {
                const elevation = payload.data
                if (!elevation) {
                    return
                }
                state.elevation = elevation
                state.loading = false
                setElevation(elevation)
            })
            .addCase(setCrop.rejected, (state) => {
                state.loading = false
            })

            //endregion

            //region Elevation

            .addCase(setProjectionLocation.pending, (state) => {
                state.loading = true
            })
            .addCase(setProjectionLocation.fulfilled, (state, {payload}) => {
                const elevation = payload.data
                if (!elevation) {
                    return
                }
                state.elevation = elevation
                state.loading = false
                setElevation(elevation)
            })
            .addCase(setProjectionLocation.rejected, (state) => {
                state.loading = false
            })

            //endregion

            //region Opening Group

            .addCase(createOpeningGroup.pending, (state) => {
                state.loading = true
            })
            .addCase(createOpeningGroup.fulfilled, (state, {payload}) => {
                const elevation = payload.data
                if (!elevation) {
                    return
                }
                state.elevation = elevation
                state.loading = false
                setElevation(elevation)
            })
            .addCase(createOpeningGroup.rejected, (state) => {
                state.loading = false
            })
            .addCase(updateOpeningGroups.pending, (state) => {
                state.loading = true
            })
            .addCase(updateOpeningGroups.fulfilled, (state, {payload}) => {
                const elevation = payload.data
                if (!elevation) {
                    return
                }
                state.elevation = elevation
                state.loading = false
                setElevation(elevation)
            })
            .addCase(updateOpeningGroups.rejected, (state) => {
                state.loading = false
            })
            .addCase(updateOpeningGroupName.pending, (state) => {
                state.loading = true
            })
            .addCase(updateOpeningGroupName.fulfilled, (state, {payload}) => {
                const elevation = payload.data
                if (!elevation) {
                    return
                }
                state.elevation = elevation
                state.loading = false
            })
            .addCase(updateOpeningGroupName.rejected, (state) => {
                state.loading = false
            })
            .addCase(deleteOpeningGroup.pending, (state) => {
                state.loading = true
            })
            .addCase(deleteOpeningGroup.fulfilled, (state, {payload}) => {
                const elevation = payload.data
                if (!elevation) {
                    return
                }
                state.elevation = elevation
                state.loading = false
                setElevation(elevation)
            })
            .addCase(deleteOpeningGroup.rejected, (state) => {
                state.loading = false
            })
            .addCase(findBestFittingOpening.pending, (state) => {
                state.loading = true
            })
            .addCase(findBestFittingOpening.fulfilled, (state, {payload}) => {
                const bestFittingOpening = payload.data
                if (!bestFittingOpening) {
                    return
                }
                state.loading = false
                setBestFittingOpening(bestFittingOpening)
            })
            .addCase(findBestFittingOpening.rejected, (state) => {
                state.loading = false
            })
            .addCase(recognizeOpeningGroup.pending, (state) => {
                state.loading = true
            })
            .addCase(recognizeOpeningGroup.fulfilled, (state, {payload}) => {
                const elevation = payload.data
                if (!elevation) {
                    return
                }
                state.elevation = elevation
                state.loading = false
                setElevation(elevation)
            })
            .addCase(recognizeOpeningGroup.rejected, (state) => {
                state.loading = false
            })
            .addCase(updateOpeningGroupMatchRate.pending, (state) => {
                state.loading = true
            })
            .addCase(updateOpeningGroupMatchRate.fulfilled, (state, {payload}) => {
                const elevation = payload.data
                if (!elevation) {
                    return
                }
                state.elevation = elevation
                state.loading = false
                setElevation(elevation)
            })
            .addCase(updateOpeningGroupMatchRate.rejected, (state) => {
                state.loading = false
            })

            //endregion

            //region Moulding

            .addCase(updateMouldings.pending, (state) => {
                state.loading = true
            })
            .addCase(updateMouldings.fulfilled, (state, {payload}) => {
                const elevation = payload.data
                if (!elevation) {
                    return
                }
                state.elevation = elevation
                state.loading = false
                setElevation(elevation)
            })
            .addCase(updateMouldings.rejected, (state) => {
                state.loading = false
            })

            //endregion

            //region Panelization Areas

            .addCase(updatePanelizationAreas.pending, (state) => {
                state.loading = true
            })
            .addCase(updatePanelizationAreas.fulfilled, (state, {payload}) => {
                const elevation = payload.data
                if (!elevation) {
                    return
                }
                state.elevation = elevation
                state.loading = false
                setElevation(elevation)
            })
            .addCase(updatePanelizationAreas.rejected, (state) => {
                state.loading = false
            })

            //endregion

            //region Material Face

            .addCase(updateMaterials.pending, (state) => {
                state.loading = true
            })
            .addCase(updateMaterials.fulfilled, (state, {payload}) => {
                const elevation = payload.data
                if (!elevation) {
                    return
                }
                state.elevation = elevation
                state.loading = false
                setElevation(elevation)
            })
            .addCase(updateMaterials.rejected, (state) => {
                state.loading = false
            })

            //endregion

            //region Architectural Lines


            .addCase(updateArchitecturalLines.pending, (state) => {
                state.loading = true
            })
            .addCase(updateArchitecturalLines.fulfilled, (state, {payload}) => {
                const elevation = payload.data
                if (!elevation) {
                    return
                }
                state.elevation = elevation
                state.loading = false
                setElevation(elevation)
            })
            .addCase(updateArchitecturalLines.rejected, (state) => {
                state.loading = false
            })

            //endregion

            //region Panelization Areas
            .addCase(invertAreasToPaneling.pending, (state) => {
                state.loading = true
            })
            .addCase(invertAreasToPaneling.fulfilled, (state) => {
                state.loading = false
            })
            .addCase(invertAreasToPaneling.rejected, (state) => {
                state.loading = false
            })
            .addCase(resetAreaToPaneling.pending, (state) => {
                state.loading = true
            })
            .addCase(resetAreaToPaneling.fulfilled, (state) => {
                state.elevation.areasToPaneling = []
                state.loading = false
            })
            .addCase(resetAreaToPaneling.rejected, (state) => {
                state.loading = false
            })
            .addCase(getSimilarOpenings.pending, (state) => {
                state.loading = true
            })
            .addCase(getSimilarOpenings.fulfilled, (state, action) => {
                state.loading = false
                state.elevation = action.payload.data
                setElevation(state.elevation)
            })
            .addCase(getSimilarOpenings.rejected, (state) => {
                state.loading = false
            })

        //endregion
    }
})

export default elevationSlice.reducer