import React, {Fragment, useEffect} from 'react';
import {makeStyles} from '@mui/styles';

import StoDialog from "../../utils/components/StoDialog";
import StoPrimaryButton from "../../utils/components/StoPrimaryButton";

import StoSnackbar from "../../utils/components/StoSnackbar";
import {addItemToLoadingQueue, createExportSheet, removeItemFromQueue} from "../../unity/react-unity-adapter";
import {useDispatch, useSelector} from "react-redux";
import {setIsExportInfoOpen, setIsExportOpen} from "../../unity/unitySlice";
import {useParams} from "react-router-dom";
import {downloadSchedulingPlanningCSV} from "../../scheduling/planing/schedulingPlaningSlice";
import {createPanelCsv} from "../../panelizationResult/panelizationResultSlice";
import {useAuth0} from "@auth0/auth0-react";
import {EventSourcePolyfill} from "event-source-polyfill";
import {downloadElevationsDXF} from "../../elevations/elevationsSlice";
import DownloadIcon from '../../utils/icons/archive.svg'

const useStyle = makeStyles(theme => ({
    root: {},
    buttonRow: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: '16px 0'
    },

    exportButton: {
        backgroundColor: `${theme.palette.primary.main}!important`,
        color: `${theme.palette.neutral.black}!important`,
        borderRadius: "0!important",
        fontSize: '13px',
        border: 'none',
        opacity: 1,
        display: 'flex',
        width: '120px',
    },
    actions: {
        height: "3.0rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",

    },

    selectorTitle: {
        fontSize: '14px',
        fontWeight: '400',
        color: theme.palette.neutral.primary80,
        margin: '2.5rem 0'
    },

    buttonRowText: {
        fontSize: '14px',
        fontWeight: '400',
        color: theme.palette.neutral.primary80,
    },


}));


function ExportDialog(props) {
    const classes = useStyle(props.theme);
    const [snackbarOpen, setSnackbarOpen] = React.useState(false)
    const [dxfDownloadSnackbarOpen, setDxfDownloadSnackbarOpen] = React.useState(false)

    const {projectId} = useParams();
    const dispatch = useDispatch()

    const isExportOpen = useSelector(state => state.unity.isExportOpen)
    const {elevations} = useSelector(state => state.elevations);

    const [isOpen, setIsOpen] = React.useState(false)

    const schedulingPlaningSlice = useSelector(state => state.schedulingPlaning);
    const {schedulingPlaning} = schedulingPlaningSlice

    const { getAccessTokenSilently } = useAuth0();

    const {panelizationResult} = useSelector(state => state.panelizationResult);
    const hasElevations = !elevations || elevations.length === 0

    useEffect(() => {
        setIsOpen(isExportOpen)
    }, [isExportOpen])


    const handleClose = () => {
        dispatch(setIsExportOpen(false))
    }

    const openSummary = async () => {
        createExportSheet()
        dispatch(setIsExportInfoOpen(true))
        handleClose()
    }


    function createAndDownloadPanelCsv() {
        //if (event.altKey && (event.ctrlKey || event.metaKey)) {
        //    downloadPanelListCsv()
        //} else {
        dispatch(createPanelCsv({projectId: projectId}))
        //}
        handleClose()
    }


    /*const getPanelCsvUrl = (projectId) => {
        // create random id with 10 digits
        const randomId = Math.floor(Math.random() * 10000000000);
        if (process.env.REACT_APP_PROFILE === 'testing' || process.env.REACT_APP_PROFILE === 'dev') {
            return `https://panelizerblobstorage.blob.core.windows.net/projects/${projectId}/3d_out/panelList.csv?rid=${randomId}`;
        } else if (process.env.REACT_APP_PROFILE === 'prod') {
            return `https://panelizerblobstorageprod.blob.core.windows.net/projects/${projectId}/3d_out/panelList.csv?rid=${randomId}`;
        }
    }*/

    function getSchedulingCsvUrl(event) {
        if (event.altKey && (event.ctrlKey || event.metaKey)) {
            downloadSchedulingPlanningAsJSON()
        } else {
            console.log("DOWNLOAD CSV")
            dispatch(downloadSchedulingPlanningCSV({projectId: projectId}))
        }
        handleClose()
    }


    /*
        function downloadPanelListCsv() {
            const element = document.createElement("a");
            const file = new Blob([JSON.stringify(schedulingPlaning)], {type: 'text/plain'});
            element.href = URL.createObjectURL(file);
            element.download = "Panels.csv";
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
        }

     */


    async function generateDXF() {
        setDxfDownloadSnackbarOpen(true)
        handleClose()
        console.log("update3dModel for projectId " + projectId + " invoked.")

        const isLocalDevelopment = window.location.toString().split("/")[2] === "localhost:3000"
        //when working locally the port must be 8080!
        const baseUrl = isLocalDevelopment ? "http://localhost:8080" : "";
        let EventSource = EventSourcePolyfill;
        let eventSource;
        if (process.env.REACT_APP_PROFILE === 'dev') {
            eventSource = new EventSource(`${baseUrl}/api/projects/${projectId}/generateDXF?panelize=` + true)
        } else if (process.env.REACT_APP_PROFILE === 'prod' || process.env.REACT_APP_PROFILE === 'testing') {
            const accessToken = await getAccessTokenSilently();
            eventSource = new EventSource(`${baseUrl}/api/projects/${projectId}/generateDXF?panelize=` + true, {
                headers: {
                    'AUTHORIZATION': 'Bearer ' + accessToken
                }
            })
        }
        addItemToLoadingQueue("GenerateDXF")

        eventSource.onmessage = event => {
            if (event.data === "model_calculated") {
                console.log("received model_calculated event")
                eventSource.close();
                dispatch(downloadElevationsDXF({projectId: projectId}))
                removeItemFromQueue("GenerateDXF")
                setDxfDownloadSnackbarOpen(false)
            }
            if (event.data === "heartbeat") {
                console.log("received heartbeat event")
            }
        }
        eventSource.onopen = event => {
            console.log("event source opened: " + JSON.stringify(event))
        }
        eventSource.onerror = event => {
            console.log("event source errored: " + JSON.stringify(event))
            eventSource.close()
        }

    }

    function downloadSchedulingPlanningAsJSON() {
        const element = document.createElement("a");
        const file = new Blob([JSON.stringify(schedulingPlaning)], {type: 'text/plain'});
        element.href = URL.createObjectURL(file);
        element.download = "schedulingPlaning.json";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }

    const getContent = () => {

        const isDisabled = panelizationResult === null || panelizationResult === undefined || panelizationResult.length === 0

        return (
            <div style={{maxWidth: '36rem'}}>
                <div className={classes.selectorTitle}>
                    Select the file format you want to export
                </div>

                <div className={classes.buttonRow}>
                    <div className={classes.buttonRowText}><b>Summary</b> (Website)</div>
                    <StoPrimaryButton
                        disabled={isDisabled}
                        tooltipTitle={isDisabled ? "Summary Website" : null}
                        tooltipText={isDisabled
                            ? "Recalculate the panelization so you can export the summary"
                            : null}
                        onClick={openSummary} title={'Open'} width={'12rem'}/>
                </div>

                <div className={classes.buttonRow}>
                    <div className={classes.buttonRowText}><b>Panellist</b> (csv-file)</div>

                    <StoPrimaryButton
                        disabled={isDisabled} title={'Download'}
                        tooltipTitle={isDisabled ? "Panellist Download" : null}
                        tooltipText={isDisabled ? "Recalculate the panelization so you can export the panellist" : null}
                        width={'12rem'} onClick={(event) =>
                        createAndDownloadPanelCsv(event)
                    }/>

                </div>
                <div className={classes.buttonRow}>
                    <div className={classes.buttonRowText}><b>Scheduling</b> (csv-file)</div>
                    <StoPrimaryButton
                        disabled={isDisabled}
                        tooltipTitle={isDisabled ? "Scheduling Download" : null}
                        tooltipText={isDisabled ? "Recalculate the panelization so you can export the scheduling" : null}
                        onClick={(event) => getSchedulingCsvUrl(event)} title={'Download'}
                        width={'12rem'}/>
                </div>
                <div className={classes.buttonRow}>
                    <div className={classes.buttonRowText}><b>Elevations</b> (dxf-file)</div>

                    <StoPrimaryButton
                        tooltipTitle={hasElevations ? "Elevations Download" : null}
                        tooltipText={hasElevations ? "No elevation is given" : null}
                        onClick={generateDXF} title={'Download'}
                        width={'12rem'}/>
                </div>

            </div>)
    }

    const getActions = () => {
        return (
            <div className={classes.actions}>
                <StoPrimaryButton title={"Cancel"} onClick={handleClose} secondary/>
            </div>)
    }



    return (
        <Fragment>
            <StoDialog open={isOpen} onClose={handleClose}
                       maxWidth={"36rem"}
                       title={"Export"}
                       content={getContent()}
                       actions={getActions()}
            />
            <StoSnackbar open={snackbarOpen}
                         vertical={'top'} horizontal={'center'}
                         duration={3000}
                         onClose={() => setSnackbarOpen(false)}
                         message={"Moulding settings successfully saved as preset"}
                         showIcon={true}
            />

            <StoSnackbar open={dxfDownloadSnackbarOpen}
                         vertical={'top'} horizontal={'center'}
                         duration={5000}
                         onClose={() => setDxfDownloadSnackbarOpen(false)}
                         message={"DXF download starts in background and needs some time"}
                         icon={DownloadIcon}
                         showIcon={true}
            />
        </Fragment>
    );
}

export default ExportDialog;