import React, {useEffect} from 'react'

import StoLogo from '../stoPan_Logo@2x.svg';
import {Unity} from 'react-unity-webgl/distribution/components/unity'
import {getUnityContext} from './UnityConfig'
import {useDispatch, useSelector} from 'react-redux'
import {
    setCreatePlan,
    setEditMouldingType,
    setIsExportInfoOpen,
    setIsExportOpen,
    setDeleteOutline,
    setLoaded,
    setProgress, setIsMainScreen
} from './unitySlice'
import {EventSourcePolyfill} from 'event-source-polyfill'
import {getClientInfo, updateClientInfo} from '../clientInfo/clientInfoSlice'
import {getProjectInfo, updateProjectInfo} from '../projectInfo/projectInfoSlice'
import {getFloorPlanSettings, updateFloorPlanSettings} from '../floorPlanSettings/floorPlanSettingsSlice'
import {
    copyOutline,
    createBalcony,
    createOutline,
    deleteBalcony,
    deleteFloor,
    getFloor,
    renameFloor, setCustomScale, setNorthOrientation,
    setScale,
    updateBalcony, updateFloorGeometries,
    updateOrigin,
    updateOutline,
} from '../floor/floorSlice'
import {getFloors} from '../floors/floorsSlice'
import {
    getCsvPanelExportSettings,
    getSettings,
    resetPanelSettings,
    updateBuildingDimensions,
    updateSettings
} from '../settings/settingsSlice'
import {
    createOpeningGroup,
    deleteElevation,
    deleteOpeningGroup,
    deleteProjectionLocation, duplicateElevation,
    findBestFittingOpening,
    getElevation,
    getSimilarOpenings,
    recognizeOpeningGroup,
    renameElevation,
    setCrop,
    setProjectionLocation,
    updateArchitecturalLines,
    updateMaterials,
    updateMouldings,
    updateOpeningGroupMatchRate, updateOpeningGroupName,
    updateOpeningGroups,
    updatePanelizationAreas
} from '../elevation/elevationSlice'
import {getElevationPlans, getFloorPlans} from '../plan/plansSlice'
import {getElevations} from '../elevations/elevationsSlice'
import {addItemToLoadingQueue, modelUpdated} from './react-unity-adapter'
import {
    getStacking,
} from '../stacking/stackingSlice'
import {
    createMaterial,
    deleteMaterial,
    getMaterials, resetMaterials,
    updateMaterial,
    updateMaterialsFromCore,
} from '../material/materialSlice'
import {getLookTypes} from '../lookType/lookTypeSlice'
import {getFinishTypes} from '../finishType/finishTypeSlice'
import {getPanelizationSettings, updatePanelizationSettings} from '../panelization/panelizationSlice'
import {getPanelizationResult} from '../panelizationResult/panelizationResultSlice'
import {getUnitSystem} from '../unitSystem/unitSystemSlice'
import {getPricingSummary, recalculatePricingSummary, updatePricingSummary} from '../pricingSummary/pricingSummarySlice'
import {getPricing, updatePanelTypePricing, updatePricingCategories} from '../pricingLevel/pricingSlice'
import {getPanelTypes, updatePanelTypes} from '../panelType/panelTypeSlice'
import {
    createMouldingType,
    deleteMouldingType,
    getMouldingTypeProfiles,
    getMouldingTypes,
    updateMouldingType,
} from '../mouldingType/mouldingTypeSlice'
import {assignHiddenFace, resetHiddenFaces} from '../hiddenFaces/hiddenFacesSlice'
import {useAuth0} from '@auth0/auth0-react'
import {getCompany, getProject, getUser, setUserRoles} from '../projects/projectsSlice'
import {getColorPalette} from '../colorPalette/colorPaletteSlice'
import {getBigBlocks, updateBigBlocks} from '../bigBlock/bigBlockSlice'
import {setCurrentView, viewTypes} from "../view/viewSlice";
import {useParams} from "react-router-dom";
import {getSchedulingSettings} from "../scheduling/Settings/schedulingSettingsSlice";
import {Typography} from "@mui/material";
import jwt_decode from "jwt-decode";
import {getCommands, updateCommands} from "../commands/commandsSlice";

function didPanelDebugSettingsChange(previous, current) {
    let changed = false
    if (previous && "panelDebug" in previous && current && "panelDebug" in current) {
        if (previous.panelDebug !== current.panelDebug) {
            changed = true;
        }
    }
    return changed;
}

function UnityViewer() {
    const unityContext = getUnityContext()

    const {getAccessTokenSilently} = useAuth0();

    const dispatch = useDispatch()

    const {projectId} = useParams()

    const progress = useSelector(state => state.unity.progress)
    const currentSettings = useSelector(state => state.settings.settings)

    const currentView = useSelector(state => state.view.currentView)


    useEffect(() => {
        if (unityContext) {
            //region Unity
            unityContext.on('loaded', _ => dispatch(setLoaded(true)))
            unityContext.on('progress', progress => dispatch(setProgress(progress)))
            unityContext.on('quitted', () => dispatch(setCurrentView(viewTypes.None)))
            //endregion
            //region Project Info
            unityContext.on('GetProjectInfo', () => dispatch(getProjectInfo({projectId: projectId})))
            unityContext.on('UpdateProjectInfo', (projectInfoJson) => dispatch(updateProjectInfo({
                projectId: projectId,
                projectInfo: JSON.parse(projectInfoJson)
            })))
            //endregion
            //region Client Info
            unityContext.on('GetClientInfo', () => dispatch(getClientInfo({projectId: projectId})))
            unityContext.on('UpdateClientInfo', (clientInfoJson) => dispatch(updateClientInfo({
                projectId: projectId,
                clientInfo: JSON.parse(clientInfoJson)
            })))
            //endregion
            //region Settings
            unityContext.on('GetSettings', () => dispatch(
                getSettings({projectId: projectId}))
            )
            unityContext.on('UpdateSettings', (settingsJson) => dispatch(updateSettings({
                projectId: projectId,
                settings: JSON.parse(settingsJson)
            })).then(() => {
                if (didPanelDebugSettingsChange(currentSettings, JSON.parse(settingsJson))) {
                    return update3dModel(true)
                }
            }))
            unityContext.on('UpdateBuildingDimensions', (buildingDimensions) => dispatch(updateBuildingDimensions({
                projectId: projectId,
                buildingDimensions: JSON.parse(buildingDimensions)
            })))
            unityContext.on('ResetPanelSettings', () => dispatch(resetPanelSettings({projectId: projectId})))
            //endregion
            //region Floor Plan Settings
            unityContext.on('GetFloorPlanSettings', () => dispatch(getFloorPlanSettings({projectId: projectId})))
            unityContext.on('UpdateFloorPlanSettings', (floorPlanSettingsJson) => dispatch(updateFloorPlanSettings({
                projectId: projectId,
                floorPlanSettings: JSON.parse(floorPlanSettingsJson)
            })))
            //endregion
            //region Plan
            unityContext.on('GetFloorPlans', () => dispatch(getFloorPlans({projectId: projectId})))
            unityContext.on('GetElevationPlans', () => dispatch(getElevationPlans({projectId: projectId})))
            //endregion
            //region Floor
            unityContext.on('GetFloors', () => dispatch(getFloors({projectId: projectId})))
            unityContext.on('GetFloor', (floorId) => {
                dispatch(getFloor({
                    projectId: projectId,
                    floorId: floorId
                }))
            })
            unityContext.on('CreateFloor', (createPlan) => dispatch(setCreatePlan(JSON.parse(createPlan)))
            )

            unityContext.on('RenameFloor', (floorId, name) => dispatch(renameFloor({
                projectId: projectId,
                floorId: floorId,
                name: name,
            })))
            unityContext.on('DeleteFloor', (floorId) => dispatch(deleteFloor({
                projectId: projectId,
                floorId: floorId
            }))
                .then(() => dispatch(getStacking({projectId: projectId}))))
            //endregion
            //region Origin
            unityContext.on('UpdateOrigin', (floorId, originJson) => dispatch(updateOrigin({
                projectId: projectId,
                floorId: floorId,
                origin: JSON.parse(originJson)
            })))
            //endregion
            //region Scale
            unityContext.on('SetScaleRatio', (floorId, scale) => dispatch(setScale({
                projectId: projectId,
                floorId: floorId,
                scale: scale,
            })))
            unityContext.on('SetCustomScale', (floorId, customScale) => dispatch(setCustomScale({
                projectId: projectId,
                floorId: floorId,
                customScale: JSON.parse(customScale),
            })))
            //endregion
            //region Outline
            unityContext.on('CreateOutline', (floorId, outlineJson) => {
                return dispatch(createOutline({
                    projectId: projectId,
                    floorId: floorId,
                    outline: JSON.parse(outlineJson)
                }))
                    .then(() => dispatch(getStacking({projectId: projectId})))

            })
            unityContext.on('UpdateOutline', (floorId, outlineJson) => dispatch(updateOutline({
                    projectId: projectId,
                    floorId: floorId,
                    outline: JSON.parse(outlineJson)
                }))
            )
            unityContext.on('DeleteOutline', (floorId, outlineId) =>
                dispatch(setDeleteOutline({
                    floorId: floorId,
                    outlineId: outlineId
                })))
            unityContext.on('UpdateFloorGeometries', (floorId, geometriesJson) => dispatch(updateFloorGeometries({
                projectId: projectId,
                floorId: floorId,
                geometries: JSON.parse(geometriesJson)
            })))
            unityContext.on('CopyOutline', (floorId, copyFloorId) => dispatch(copyOutline({
                projectId: projectId,
                floorId: floorId,
                copyFloorId: copyFloorId
            })))
            //endregion
            //region Origin
            unityContext.on('UpdateOrigin', (floorId, originJson) => {
                    console.log("Updating Origin")
                    dispatch(updateOrigin({
                        projectId: projectId,
                        floorId: floorId,
                        origin: JSON.parse(originJson)
                    }))
                }
            )
            //endregion

            //region Balcony
            unityContext.on('CreateBalcony', (floorId, balconyJson) => dispatch(createBalcony({
                projectId: projectId,
                floorId: floorId,
                balcony: JSON.parse(balconyJson)
            })))

            unityContext.on('UpdateBalcony', (floorId, balconyJson) => dispatch(updateBalcony({
                projectId: projectId,
                floorId: floorId,
                balcony: JSON.parse(balconyJson)
            })))

            unityContext.on('DeleteBalcony', (floorId, balconyId) => dispatch(deleteBalcony({
                projectId: projectId,
                floorId: floorId,
                balconyId: balconyId
            })))
            //endregion
            //region North Orientation
            unityContext.on('SetNorthOrientation', (floorId, northOrientationJson) => dispatch(setNorthOrientation({
                projectId: projectId,
                floorId: floorId,
                northOrientation: JSON.parse(northOrientationJson)
            })))
            //endregion
            //region Stacking
            unityContext.on('GetStacking', () => dispatch(getStacking({projectId: projectId})))
            //endregion
            //region Elevation
            unityContext.on('GetElevations', () => dispatch(getElevations({projectId: projectId})))
            unityContext.on('GetElevation', (elevationId, forceUpdate) => {
                dispatch(getElevation({
                    projectId: projectId,
                    elevationId: elevationId,
                    forceUpdate: forceUpdate
                }))
            })
            unityContext.on('CreateElevation', (createPlan) => dispatch(setCreatePlan(JSON.parse(createPlan))))

            unityContext.on('RenameElevation', (elevationId, name) => dispatch(renameElevation({
                projectId: projectId,
                elevationId: elevationId,
                name: name,
            })))
            unityContext.on('DuplicateElevation', (elevationId, cropJson) => dispatch(duplicateElevation({
                projectId: projectId,
                elevationId: elevationId,
                crop: JSON.parse(cropJson)
            })))
            unityContext.on('DeleteElevation', (elevationId) => dispatch(deleteElevation({
                projectId: projectId,
                elevationId: elevationId
            })))
            //endregion
            //region Crop
            unityContext.on('SetCrop', (elevationId, cropJson) => dispatch(setCrop({
                projectId: projectId,
                elevationId: elevationId,
                crop: JSON.parse(cropJson),
            })))
            //endregion
            //region Projection
            unityContext.on('SetProjectionLocation', (elevationId, location) => {
                dispatch(setProjectionLocation({
                    projectId: projectId,
                    elevationId: elevationId,
                    location: JSON.parse(location),
                })).then(() => update3dModel(false))
            })
            unityContext.on('DeleteProjectionLocation', (elevationId) => dispatch(deleteProjectionLocation({
                projectId: projectId,
                elevationId: elevationId,
            })).then(() => update3dModel(false)))
            //endregion

            //region Panelization Area
            unityContext.on('UpdatePanelizationAreas', (elevationId, panelizationAreasJson) => dispatch(updatePanelizationAreas({
                projectId: projectId,
                elevationId: elevationId,
                panelizationAreas: JSON.parse(panelizationAreasJson),
            })))
            //endregion
            //region Opening Group
            unityContext.on('CreateOpeningGroup', (elevationId, openingGroupJson) => dispatch(createOpeningGroup({
                projectId: projectId,
                elevationId: elevationId,
                openingGroup: JSON.parse(openingGroupJson),
            })))
            unityContext.on('UpdateOpeningGroups', (elevationId, openingGroupsJson) => dispatch(updateOpeningGroups({
                projectId: projectId,
                elevationId: elevationId,
                openingGroups: JSON.parse(openingGroupsJson),
            })))
            unityContext.on('UpdateOpeningGroupName', (elevationId, openingGroup) => {
                return dispatch(updateOpeningGroupName({
                    projectId: projectId,
                    elevationId: elevationId,
                    openingGroup: JSON.parse(openingGroup),
                }))
            })
            unityContext.on('DeleteOpeningGroup', (elevationId, openingGroupId) => dispatch(deleteOpeningGroup({
                projectId: projectId,
                elevationId: elevationId,
                openingGroupId: openingGroupId,
            })))
            unityContext.on('FindBestFittingOpening', (elevationId, initialPositionJson) => dispatch(findBestFittingOpening({
                projectId: projectId,
                elevationId: elevationId,
                initialPosition: JSON.parse(initialPositionJson),
            })))
            unityContext.on('RecognizeOpeningGroup', (elevationId, finalPositionJson) => dispatch(recognizeOpeningGroup({
                projectId: projectId,
                elevationId: elevationId,
                finalPosition: JSON.parse(finalPositionJson),
            })))
            unityContext.on('GetSimilarOpenings', (elevationId) => dispatch(getSimilarOpenings({
                projectId: projectId,
                elevationId: elevationId,
            })))
            unityContext.on('UpdateOpeningGroupMatchRate', (elevationId, openingGroupJson) => dispatch(updateOpeningGroupMatchRate({
                projectId: projectId,
                elevationId: elevationId,
                openingGroup: JSON.parse(openingGroupJson),
            })))
            //endregion
            //region Moulding
            unityContext.on('UpdateMouldings', (elevationId, mouldingsJson) => dispatch(updateMouldings({
                projectId: projectId,
                elevationId: elevationId,
                mouldings: JSON.parse(mouldingsJson),
            })))
            //endregion
            //region Moulding
            unityContext.on('GetMouldingTypes', () => dispatch(getMouldingTypes({
                projectId: projectId,
            })))
            unityContext.on('CreateMouldingType', () => dispatch(createMouldingType({
                    projectId: projectId,
                }))
                    .then(() => dispatch(getPricing({projectId: projectId})))
            )

            unityContext.on('EditMouldingType', (mouldingTypeId) => dispatch(setEditMouldingType(mouldingTypeId))
            )

            unityContext.on('UpdateMouldingType', (mouldingTypeJson) => dispatch(updateMouldingType({
                    projectId: projectId,
                    mouldingType: JSON.parse(mouldingTypeJson),
                }))
                    .then(() => dispatch(getPricing({projectId: projectId})))
            )
            unityContext.on('DeleteMouldingType', (mouldingTypeId) => dispatch(deleteMouldingType({
                    projectId: projectId,
                    mouldingTypeId: mouldingTypeId,
                }))
                    .then(() => dispatch(getPricing({projectId: projectId})))
            )
            //endregion
            //region Material Face
            unityContext.on('UpdateMaterials', (elevationId, materialLines, materialFaces) => dispatch(updateMaterials({
                projectId: projectId,
                elevationId: elevationId,
                materialLines: JSON.parse(materialLines),
                materialFaces: JSON.parse(materialFaces)
            })))
            //endregion
            //region Material
            unityContext.on('GetMaterials', () => dispatch(getMaterials({projectId: projectId})))
            unityContext.on('CreateMaterial', () => dispatch(createMaterial({projectId: projectId})))
            unityContext.on('ResetMaterials', () => dispatch(resetMaterials({projectId: projectId}))
                .then(() => update3dModel(true)))
            unityContext.on('UpdateMaterial', (material) => dispatch(updateMaterial({
                projectId: projectId,
                material: JSON.parse(material)
            })))
            unityContext.on('DeleteMaterial', (materialId) => dispatch(deleteMaterial({
                    projectId: projectId,
                    materialId: materialId
                })).then(() => dispatch(getMaterials({projectId: projectId})))
                    .then(() => dispatch(getElevations({projectId: projectId})))
            )
            //endregion
            //region Architectural Line
            unityContext.on('UpdateArchitecturalLines', (elevationId, architecturalLinesJson) => dispatch(updateArchitecturalLines({
                projectId: projectId,
                elevationId: elevationId,
                architecturalLines: JSON.parse(architecturalLinesJson),
            })))
            //endregion
            //region PanelizationSettings

            unityContext.on('GetPanelizationSettings', () => dispatch(getPanelizationSettings({projectId: projectId})))
            unityContext.on('UpdatePanelizationSettings', (panelizationSettings) => dispatch(updatePanelizationSettings({
                projectId: projectId,
                panelizationSettings: JSON.parse(panelizationSettings),
            })))


            unityContext.on('GetPanelizationResult', () => dispatch(getPanelizationResult({projectId: projectId}))
            )

            //endregion


            //region Look Type

            unityContext.on('GetLookTypes', () => dispatch(getLookTypes()))

            //endregion

            //region Look Type

            unityContext.on('GetFinishTypes', () => dispatch(getFinishTypes()))

            //endregion

            //region Panel Type

            unityContext.on('UpdatePanelTypes', (panelTypes) => dispatch(updatePanelTypes({
                    projectId: projectId,
                    panelTypes: JSON.parse(panelTypes)
                }))
                    .then(() => dispatch(getPricing({projectId: projectId})))
            )

            //endregion

            //region Pricing Level

            unityContext.on('UpdatePanelTypePricing', (panelTypePricesJson) => dispatch(updatePanelTypePricing({
                    projectId: projectId,
                    panelTypePrices: JSON.parse(panelTypePricesJson)
                }))
                    .then(() => dispatch(getPricing({projectId: projectId})))
            )

            unityContext.on('UpdatePricingCategories', (pricingCategoriesJson) => {
                    dispatch(updatePricingCategories({
                        projectId: projectId,
                        updatePricingCategories: JSON.parse(pricingCategoriesJson)
                    }))
                        .then(() => dispatch(getMouldingTypes({projectId: projectId})))
                }
            )

            unityContext.on('UpdateBigBlocks', (bigBlocks) => {
                    dispatch(updateBigBlocks({
                        projectId: projectId,
                        bigBlocks: JSON.parse(bigBlocks)
                    })).then(()=>{

                        const blocks = JSON.parse(bigBlocks)
                        console.log(blocks)
                        if(blocks.customPoint != null) {
                            update3dModel(true)
                            dispatch(getFloors({projectId: projectId}))
                        }
                    })
                }
            )

            //

            //region Pricing Summary

            unityContext.on('UpdatePricingSummary', (pricingSummary) => dispatch(updatePricingSummary({
                projectId: projectId,
                pricingSummary: JSON.parse(pricingSummary)
            })))

            //endregion

            //region Hidden Faces
            unityContext.on('AssignHiddenFace', (hiddenFaceMapping) => dispatch(assignHiddenFace({
                projectId: projectId,
                hiddenFace: JSON.parse(hiddenFaceMapping),
            })).then(() => update3dModel(true)))
            unityContext.on('ResetHiddenFaces', () => dispatch(resetHiddenFaces({
                projectId: projectId,
            })).then(() => update3dModel(true)))
            //endregion

            //region 3D Model

            unityContext.on('Update3dModel', (panelize) =>
                update3dModel(panelize)
            )

            unityContext.on('GetModelUrlFromBackend', (panelize) => {
                    load3dModel(panelize)
                    //dispatch(getPricingSummary({projectId: projectId}))
                }
            )

            unityContext.on('OpenExportDialog', () => dispatch(setIsExportOpen(true)))
            unityContext.on('CloseExportDialogInReact', () => dispatch(setIsExportInfoOpen(false)))

            //endregion

            unityContext.on('UpdateMaterialsFromCore', () => dispatch(updateMaterialsFromCore({
                projectId: projectId
            })))

            unityContext.on('SetCurrentView', (oldView, currentView) => dispatch(setCurrentView(currentView)))
            unityContext.on('Set3DViewToMainScreen', (isMainScreen) => dispatch(setIsMainScreen(isMainScreen)))

            unityContext.on('UpdateCommands', (commandJson) => dispatch(updateCommands({
                projectId: projectId,
                commands: JSON.parse(commandJson)
            })))
        }
    })
    const getUserRoles = async () => {
        if (process.env.REACT_APP_PROFILE === 'testing' || process.env.REACT_APP_PROFILE === 'prod') {
            const accessToken = await getAccessTokenSilently();
            const decoded = jwt_decode(accessToken)
            dispatch(setUserRoles(decoded.permissions))
        } else {
            dispatch(setUserRoles(["admin:all", "admin:support"]))
        }

    }
    useEffect(() => {
        if (progress === 1) {
            const props = {projectId: projectId}
            dispatch(getProject(props))
            dispatch(getSchedulingSettings(props))
            dispatch(getLookTypes())
            dispatch(getFinishTypes())
            dispatch(getColorPalette())
            dispatch(getUnitSystem(props))
                .then(() => {
                    dispatch(getSettings(props))
                    dispatch(getCsvPanelExportSettings(props))
                    dispatch(getPricing(props))
                    dispatch(getPricingSummary(props))
                    dispatch(getFloors(props))
                    dispatch(getElevations(props))
                    dispatch(getMouldingTypes(props))
                    dispatch(getMouldingTypeProfiles(props))
                    dispatch(getPanelizationSettings(props))
                    dispatch(getStacking(props))
                    dispatch(getBigBlocks(props))
                    dispatch(getCommands(props))

                })
            dispatch(getUser())
            dispatch(getCompany())
            getUserRoles()

            dispatch(getPanelTypes(props))
            dispatch(getClientInfo(props))
            dispatch(getProjectInfo(props))

            dispatch(updateMaterialsFromCore(props))


        }
        //eslint-disable-next-line
    }, [projectId, dispatch, progress])


    function load3dModel(panelize) {
        console.log("LOADING 3D MODEL")
        if (process.env.REACT_APP_PROFILE === 'testing' || process.env.REACT_APP_PROFILE === 'dev') {
            modelUpdated(`https://panelizerblobstorage.blob.core.windows.net/projects/${projectId}/3d_out/obj/building_001.obj?random=` + Math.floor(Math.random() * 999999));
        } else if (process.env.REACT_APP_PROFILE === 'prod') {
            modelUpdated(`https://panelizerblobstorageprod.blob.core.windows.net/projects/${projectId}/3d_out/obj/building_001.obj?random=` + Math.floor(Math.random() * 999999));
        }
        dispatch(updateMaterialsFromCore({projectId: projectId}))
            .then(() => dispatch(getPanelizationResult({projectId: projectId})))
            .then(() => panelize ? dispatch(recalculatePricingSummary({projectId: projectId})) : null)

    }

    async function update3dModel(panelize) {
        console.log("update3dModel for projectId " + projectId + " invoked.")
        addItemToLoadingQueue("CalculateModel");
        // If the frontend is running as yarn build, this call is routed to localhost:8080,
        // otherwise the correct port is the application's port itself
        const isLocalDevelopment = window.location.toString().split("/")[2] === "localhost:3000"
        //when working locally the port must be 8080!
        const baseUrl = isLocalDevelopment ? "http://localhost:8080" : "";
        let EventSource = EventSourcePolyfill;
        let eventSource;
        if (process.env.REACT_APP_PROFILE === 'dev') {
            eventSource = new EventSource(`${baseUrl}/api/projects/${projectId}/update3dModel?panelize=` + panelize)
        } else if (process.env.REACT_APP_PROFILE === 'prod' || process.env.REACT_APP_PROFILE === 'testing') {
            const accessToken = await getAccessTokenSilently();
            eventSource = new EventSource(`${baseUrl}/api/projects/${projectId}/update3dModel?panelize=` + panelize, {
                headers: {
                    'AUTHORIZATION': 'Bearer ' + accessToken
                }
            })
        }

        eventSource.onmessage = event => {
            if (event.data === "model_calculated") {
                console.log("received model_calculated event")
                eventSource.close();
                load3dModel(panelize)

            }
            if (event.data === "heartbeat") {
                console.log("received heartbeat event")
            }
        }
        eventSource.onopen = event => {
            console.log("event source opened: " + JSON.stringify(event))
        }
        eventSource.onerror = event => {
            console.log("event source errored: " + JSON.stringify(event))
            eventSource.close()
        }
    }

    const loadingScreen = <div style={{
        position: "absolute",
        width: "400px",
        height: "200px",
        left: "calc(50% - 200px)",
        top: "calc(40% - 100px)",
        background: "#ffffff"
    }}>
        <img src={StoLogo} style={{position: "absolute", left: "110px", top: "40px"}} alt={'stoLogo'}/>

        <div style={{
            border: "solid 3px",
            width: "300px",
            height: "30px",
            position: "absolute",
            left: "50px",
            top: "82px"
        }}>
            <div style={{
                width: 294 * progress + "px",
                height: "24px",
                position: "absolute",
                left: "3px",
                top: "3px",
                background: "#ffd700"
            }}/>
        </div>
        <Typography variant={"h4"} style={{position: "absolute", textAlign: "center", width: "100%", top: "120px"}}>Loading
            Application
        </Typography>
    </div>


    let loadingState = progress < 1 && progress > 0 ? loadingScreen : null

    const showUnity = true


    function blockTabOnFloorView(event) {
        if (event.key === 'Tab' && viewTypes.FloorPlan === currentView) {
            event.preventDefault()
        }
    }

    return unityContext ?
        <div onKeyDown={blockTabOnFloorView}>
            {loadingState}
            {showUnity && <Unity matchWebGLToCanvasSize={true} unityContext={unityContext}
                                 style={{width: '100%', height: 'calc(100vh - 50px)'}} tabIndex={1}/>}
        </div>
        : null
}

export default UnityViewer

